import { forwardRef, ButtonHTMLAttributes, ReactNode } from 'react';
import Icon from './Icon';
import classNames from 'classnames';

// Button 컴포넌트의 Props 타입 정의
interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'small' | 'default' | 'large';
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost' | 'negative' | 'normal' | 'text' | 'soft';
  prefixIcon?: string;
  suffixIcon?: string;
  iconOnly?: string;
  children: ReactNode;
  className?: string;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ size = 'default', variant = 'outline', children, prefixIcon, suffixIcon, iconOnly, className, ...props }, ref) => {
    return (
      <button
        type="button"
        ref={ref}
        className={classNames(
          'dsx-Button',
          size !== 'default' && `dsx-Button--${size}`,
          `dsx-Button--${variant}`,
          className,
        )}
        {...props}
      >
        {iconOnly ? (
          <>
            <Icon name={iconOnly} />
            <span className="dsx-blind">{children}</span>
          </>
        ) : (
          <>
            {prefixIcon && <Icon name={prefixIcon} />}
            <span>{children}</span>
            {suffixIcon && <Icon name={suffixIcon} />}
          </>
        )}
      </button>
    );
  },
);

Button.displayName = 'Button';
