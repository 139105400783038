import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, DropdownMenu, Tooltip } from '../../components/base';
import Badge from '../../components/base/Badge';
import Icon from '../../components/base/Icon';
import { IconButton } from '../../components/base/IconButton';
import { Link } from '../../components/base/Link';
import { EOpenScopeType, IPluginPromptData } from '../../libs/models/studio/PluginPromptList';
import { useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import TimeDisplay from '../../components/base/TimeDisplay';

/**
 *============= 백엔드에서 넘어오는 상태값 =============
 * CreatedStatus
 *      Registration(등록중)
        Learning(학습중) - 1
        LearningComplete(학습완료) - 2
        Complete(완료) - 3
 * 
 * VersionStatus
 *      Draft(임시저장) - 0   => CreatedStatus가 (Registration, Learning, LearningComplete)이 될 수 있음.
 *      Create(최초) - 1
        Update(업데이트버전) - 2
 *
 * openScope
 *      Public(공개) - 0
 *      Private(비공개) - 1
 * 
 * createdStatus
 *      프로필 생성 - 1
 *      상세 설정 - 2
 *      등록 - 3
 *    
 *        
 ============= 퍼블리싱 상태값 =============     
 * cardState = 'ready', // ready(사용가능), delete(삭제), learning(학습중), complete(학습완료), waiting(등록중)       
       
 * @param props 
 * @returns 
 */

interface IPluginPromptCard extends IPluginPromptData {
  menuType: 'plugin' | 'prompt';
  onRestore?: (menuType: 'plugin' | 'prompt', id: string) => void;
  onDelete?: (menuType: 'plugin' | 'prompt', id: string) => void;
  onRemove?: (menuType: 'plugin' | 'prompt', id: string) => void;
  // onBookmark?: (menuType: 'plugin' | 'prompt', id: string, bookmarked: boolean) => void;
  onChangeOpenScope?: (menuType: 'plugin' | 'prompt', id: string, openScope: EOpenScopeType) => void;
  onCardClick?: () => void;
  activeTabKey?: string;
  isLoading?: boolean;
  setMyPluginState?: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>;
  setMyPromptState?: React.Dispatch<React.SetStateAction<IPluginPromptData[]>>;
  onBookmarkToggle?: (id: string, bookmarked: boolean) => void;
}

const PluginPromptCard: React.FC<IPluginPromptCard> = ({
  menuType,
  onRestore,
  onDelete,
  onRemove,
  // onBookmark,
  onCardClick,
  onChangeOpenScope,
  activeTabKey,
  isLoading,
  setMyPluginState,
  setMyPromptState,
  onBookmarkToggle,
  ...props
}) => {
  const navigation = useNavigate();
  const { user } = useAppSelector((state: RootState) => state.users);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [bookmarkCount, setBookmarkCount] = useState(Number(props.bookmarkUserCount));

  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    // props의 bookmarkUserCount가 변경될 때마다 업데이트
    setBookmarkCount(Number(props.bookmarkUserCount));
  }, [props.bookmarkUserCount]);

  useEffect(() => {
    // 북마크 상태가 변경될 때마다 count를 업데이트
    setIsBookmarked(!!props.bookmarkUserIds?.find((userId) => userId === user.userId));
    // setBookmarkCount(props.bookmarkUserCount as number);
  }, [props.bookmarkUserIds, user.userId]);

  const mymenuOptions = [
    {
      value: 'Menu1',
      label: '편집',
      icon: 'edit',
      onClick: () => {
        menuType === 'plugin'
          ? navigation(`/makers/plugin/pluginCreate?id=${props.id}`)
          : navigation(`/makers/prompt/promptCreate?id=${props.id}`);
      },
    },
    {
      value: 'Menu2',
      label: '삭제',
      icon: 'delete',
      onClick: () => {
        if (onDelete) {
          onDelete(menuType, props.id as string);
          if (menuType === 'plugin') {
            if (setMyPluginState) {
              setMyPluginState((prevState) => prevState.filter((item) => item.id !== props.id));
            }
          } else {
            if (setMyPromptState) {
              setMyPromptState((prevState) => prevState.filter((item) => item.id !== props.id));
            }
          }
        }
      },
    },
  ];

  if (activeTabKey === 'MyPlugin') {
    mymenuOptions.push({
      value: 'Menu3',
      label: props.openScope?.valueOf() === 'Public' ? '나만보기' : '전체공개',
      icon: props.openScope?.valueOf() === 'Public' ? 'eyeSlash' : 'eye',
      onClick: () => {
        if (onChangeOpenScope) {
          onChangeOpenScope(menuType, props.id as string, props.openScope as EOpenScopeType);
        }
      },
    });
  }

  const deleteOptions = [
    {
      value: 'Menu1',
      label: '되돌리기',
      icon: 'return',
      onClick: () => {
        if (onRestore) {
          onRestore(menuType, props.id as string);
          if (menuType === 'plugin') {
            if (setMyPluginState) {
              setMyPluginState((prevState) => prevState.filter((item) => item.id !== props.id));
            }
          } else {
            if (setMyPromptState) {
              setMyPromptState((prevState) => prevState.filter((item) => item.id !== props.id));
            }
          }
        }
      },
    },
    {
      value: 'Menu2',
      label: '영구삭제',
      icon: 'delete',
      onClick: () => {
        if (onRemove) {
          onRemove(menuType, props.id as string);
          if (menuType === 'plugin') {
            if (setMyPluginState) {
              setMyPluginState((prevState) => prevState.filter((item) => item.id !== props.id));
            }
          } else {
            if (setMyPromptState) {
              setMyPromptState((prevState) => prevState.filter((item) => item.id !== props.id));
            }
          }
        }
      },
    },
  ];

  /**
   * [Func] 스타일 적용 함수
   * @returns
   */
  const checkStyleFn = () => {
    return 'ready';
  };

  const handleBookmarkClick = () => {
    const localBookmark = !isBookmarked;
    setIsBookmarked(localBookmark);

    if (onBookmarkToggle) {
      onBookmarkToggle(props.id as string, localBookmark);
    }

    // bookmarkCount 업데이트
    setBookmarkCount((prevCount) => (localBookmark ? prevCount + 1 : prevCount - 1));
  };

  const renderVersionStatusMessage = () => {
    const elements = [];

    if (props.versionStatus === 'Draft') {
      elements.push(
        <span key="draft" className="add-msg">
          임시저장됨
        </span>,
      );
    }

    if (props.versionStatus === 'Update') {
      elements.push(
        <span key="update" className="add-msg">
          버전 업데이트
        </span>,
      );
    }

    if (props.createdStatus === 'Complete' && props.versionStatus !== 'Draft' && !props.deleted) {
      elements.push(
        <IconButton
          key="bookmark"
          name={isBookmarked ? 'bookmarkFill' : 'bookmark'}
          size="large"
          className="check-bookmark"
          aria-selected={isBookmarked}
          onClick={handleBookmarkClick}
        >
          즐겨찾기
        </IconButton>,
      );
    }

    return elements.length ? elements : null;
  };

  if (isLoading) {
    return <div className="studio-card-item is-skeleton"></div>;
  }

  return (
    <div
      className={classNames('studio-card-item', `is-${checkStyleFn()}`, { 'is-active': isHovered })}
      onMouseLeave={handleMouseLeave}
    >
      <Link className="card-body" onMouseEnter={handleMouseEnter} onClick={onCardClick}>
        <Avatar className="b-img" src={props.profileUrl as string} label="" />

        {/* <div className="b-img">
          <img src={props.profileUrl as string} />
        </div> */}
        <div className="b-tag">
          <Badge variant="tint" size="large" accent="primary">
            {menuType === 'plugin' ? props.pluginCategory?.categoryName : props.promptCategory?.categoryName}
          </Badge>
          <Badge variant="tint" size="large" accent="primary">
            {props.model}
          </Badge>
        </div>
        <strong className="b-tit">{props.name}</strong>
        <p className="b-txt">{props.description}</p>
      </Link>

      {props.createdStatus === 'Learning' ? (
        <div className="card-foot">
          <Tooltip variant="inverse" message="학습이 완료되면 테스트를 해 보세요" position={['bottom', 'start']}>
            <em className="f-state f-learning">
              <Icon name="infoFill" />
              <span className="msg">학습중...</span>
            </em>
          </Tooltip>
        </div>
      ) : props.createdStatus === 'LearningComplete' ? (
        <div className="card-foot">
          <Tooltip variant="inverse" message="테스트 후 배포해 보세요" position={['bottom', 'start']}>
            <em className="f-state f-complete">
              <Icon name="infoFill" />
              <span className="msg">학습완료!</span>
            </em>
          </Tooltip>
        </div>
      ) : props.createdStatus === 'Registration' ? (
        <div className="card-foot">
          <Tooltip variant="inverse" message="플러그인 등록을 완료해주세요" position={['bottom', 'start']}>
            <em className="f-state f-waiting">
              <Icon name="infoFill" />
              <span className="msg">등록중...</span>
            </em>
          </Tooltip>
        </div>
      ) : (
        <div className="card-foot">
          <Tooltip variant="inverse" message="즐겨찾기 수" position={['bottom', 'start']}>
            <em className="f-favorit">
              <Icon name="heart" />
              <span className="msg">{bookmarkCount}</span>
            </em>
          </Tooltip>
          <span className="f-date">
            {props.updatedTime ? <TimeDisplay updatedTime={props.updatedTime} /> : 'No date'}
          </span>
        </div>
      )}

      {/* <div className="sub">
        <em className="s-favorit">
          <Icon name="heart" />
        </em>
        <span className="s-date">{props.createdTime ? <Moment fromNow>{props.createdTime}</Moment> : 'No date'}</span>
      </div> */}
      {props.createdStatus === 'Complete' && props.versionStatus !== 'Draft' && (
        <Link className="card-hover-link" to="#" suffixIcon="arrow" onClick={onCardClick}>
          {menuType == 'plugin' ? '플러그인 사용하기' : '프롬프트 사용하기'}
        </Link>
      )}
      <div className="card-float">
        {renderVersionStatusMessage()}
        {(props.managedMembers?.find(member => member.user.userId == user.userId) ?? (props.createdMember?.userId === user.userId || activeTabKey === 'MyPlugin')) && (
          <DropdownMenu
            label="더보기"
            options={props.deleted ? deleteOptions : mymenuOptions}
            trigger={<IconButton name="moreHorizon">더보기</IconButton>}
            position={['bottom', 'end']}
          />
        )}
      </div>
    </div>
  );
};

export default PluginPromptCard;
