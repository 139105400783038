import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Heading, IconButton } from '../../../components/base';
import { Button as Icon } from '../../../components/Button';
import { useAlert } from '../../../context/AlertContext';
import { usePlugin } from '../../../libs/hooks/usePlugin';
import {
  AuthorityType,
  ECreatedStatusType,
  EOpenScopeType,
  EVersionStatusType,
  IPluginPromptData,
} from '../../../libs/models/studio/PluginPromptList';
import { useAppDispatch, useAppSelector } from '../../../redux/app/hooks';
import { RootState } from '../../../redux/app/store';
import PluginCreateForm from './PluginCreateForm';
import PluginCreatePreview from './PluginCreatePreview';
import { showAlertToast } from '../../../redux/features/app/appSlice';
import { AlertType } from '../../../libs/models/AlertType';
import { Constants } from '../../../Constants';

const LoadingSpinner = () => {
  return (
    <div className="dsx-ModalOverlay">
      <div className="works-loading">
        <span className="loading" aria-hidden="true"></span>
      </div>
    </div>
  );
};

const PluginCreate = () => {
  const plugin = usePlugin();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const { user } = useAppSelector((state: RootState) => state.users);
  const dispatch = useAppDispatch();
  const [isRegistLoading, setIsRegistLoading] = useState(false);

  const [formData, setFormData] = useState<IPluginPromptData>({
    partitionKey: Constants.companyCode,
    deleted: false,
    companyCode: Constants.companyCode,
    pluginCategoryId: '',
    name: '',
    description: '',
    model: '',
    profileUrl: '',
    openScope: EOpenScopeType.Private,
    customPersona: '',
    ragFileIds: [],
    ragContentsData: [],
    ragContentsDataIds: [],
    conversationStarters: [],
    bookmarkUserIds: [],
    enabled: true,
    createdMember: user,
    createdStep: 1,
    versionStatus: EVersionStatusType.Draft, // 임시저장
    createdStatus: ECreatedStatusType.Registration, // 등록중
    managedMembers: [{authorityType: AuthorityType.Owner, user:user}]
  });

  useEffect(() => {
    if (query.get('id')) {
      void handleCallDetail();
    } else {
      handleSave('temp');
    }
  }, [query.get('id')]);

  /**
   * [Func] 상세 조회
   */
  const handleCallDetail = async () => {
    try {
      const res = await plugin.getPlugin(query.get('id') as string);
      setFormData({ ...res });
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * [Func] 임시저장, 저장
   * @param type
   */
  const handleCallSave = async (type: string, isShowAlert?: boolean) => {
    if (type === 'temp') {
      // 임시 저장
      try {
        if (formData.id) {
          const res = await plugin.postPluginDraft({
            ...formData,
            conversationStarters: formData.conversationStarters?.filter((starter) => starter !== ''),
            createdStatus: formData.createdStatus === 'Complete' ? 'Registration' : formData.createdStatus,
          });
          if (res.statusCode === 200) {
            setFormData((prev) => ({ ...prev, id: formData.id }));
            isShowAlert &&
              dispatch(
                showAlertToast({
                  altMessage: '임시저장 되었습니다.',
                  altType: AlertType.Info,
                }),
              );
          } else {
            dispatch(
              showAlertToast({
                altMessage: String(res.value.message),
                altType: AlertType.Info,
              }),
            );
          }
        } else {
          const res = await plugin.postPluginDraft({
            ...formData,
            conversationStarters: formData.conversationStarters?.filter((starter) => starter !== ''),
          });
          if (res.statusCode === 200) {
            setFormData((prev) => ({
              ...prev,
              id: res.value.id,
            }));
          } else {
            dispatch(
              showAlertToast({
                altMessage: String(res.value.message),
                altType: AlertType.Info,
              }),
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      // 배포하기
      showAlert({
        title: '플러그인을 등록하시겠어요?',
        message: (
          <div className="alert-box">
            <strong className="tit">공개 범위</strong>{' '}
            <span className="con">{formData.openScope === EOpenScopeType.Public ? '전체공개' : '나만보기'}</span>
          </div>
        ),
        confirmLabel: '배포하기',
        showCancel: true,
        onConfirm: () => void handleRegist(),
      });
    }
  };

  /**
   * 배포하기
   */
  const handleRegist = async () => {
    setIsRegistLoading(true);
    try {
      // CASE.1 임시저장인 상태에서, id 값이 있는경우
      if ((formData.versionStatus === 'Draft' || formData.versionStatus === EVersionStatusType.Draft) && formData.id) {
        // 임시저장된 상태에서 아무것도 변경되지 않고 상태만 등록으로 변경
        await plugin.postPlugin({
          ...formData,
          conversationStarters: formData.conversationStarters?.filter((starter) => starter !== ''),
          createdStep: 1, // 등록시 step1 으로 저장
          versionStatus: EVersionStatusType.Creat, // 최초
          createdStatus: ECreatedStatusType.Complete,
        });
        setFormData((prev) => ({ ...prev, id: formData.id }));
        setIsRegistLoading(false);
        navigate('/makers/plugin');
        // CASE.2 최초 등록 상태에서, id 값이 있는경우 or CASE.3 업데이트 상태에서, id 값이 있는경우
      } else if (
        (formData.versionStatus === 'Creat' ||
          formData.versionStatus === EVersionStatusType.Creat ||
          formData.versionStatus === 'Update' ||
          formData.versionStatus === EVersionStatusType.Update) &&
        formData.id
      ) {
        await plugin.postPlugin({
          ...formData,
          conversationStarters: formData.conversationStarters?.filter((starter) => starter !== ''),
          createdStep: 1, // 등록시 step1 으로 저장
          versionStatus: EVersionStatusType.Update, // 업데이트버전
          createdStatus: ECreatedStatusType.Complete,
        });
        setFormData((prev) => ({ ...prev, id: formData.id }));
        setIsRegistLoading(false);
        navigate('/makers/plugin');
      }
    } catch (error) {
      console.log(error);
    }
    setIsRegistLoading(false);
  };

  /**
   * [Func] 임시저장, 저장
   * @param type
   */
  const handleSave = (type: string, isShowAlert?: boolean) => {
    void handleCallSave(type, isShowAlert);
  };

  const handleBack = () => {
    showAlert({
      title: '저장하지 않고 페이지를 나가시겠습니까?',
      message: '변경사항이 저장되지 않을 수 있습니다.',
      confirmLabel: '나가기',
      showCancel: true,
      onConfirm: () => {
        navigate('/makers/plugin');
      },
    });
  };

  const handleSubmit = () => {
    showAlert({
      title: '플러그인을 등록하시겠습니까?',
      message: (
        <div className="alert-box">
          <strong className="tit">공개 범위</strong> <span className="con">전체공개</span>
        </div>
      ),
      confirmLabel: '배포하기',
      showCancel: true,
      onConfirm: () => {
        handleSave('save');
      },
    });
  };

  const handleDelete = () => {
    showAlert({
      title: '플러그인을 삭제하시겠습니까?',
      message: (
        // <div className="alert-box">
          (formData.versionStatus === 'Draft' || formData.versionStatus === EVersionStatusType.Draft) ?
          '임시저장된 데이터는 복구 할 수 없습니다.'
           : '삭제 전에 다시 한번 확인해주세요.'
        // {/* </div> */}
      ),
      confirmLabel: '삭제하기',
      showCancel: true,
      onConfirm: () => {
        setIsRegistLoading(true);
        plugin.deletePlugin(formData).then(()=>{
          setIsRegistLoading(false);
          navigate('/makers/plugin');
        }).catch(()=>{
          setIsRegistLoading(false);
        });
        
      },
    });
  }

  return (
    <div className="create-wrapper">
      {isRegistLoading && <LoadingSpinner />}
      <div className="create-header">
        <IconButton name="chevronLeft" size="large" onClick={handleBack}>
          뒤로가기
        </IconButton>
        <Heading as="h1" size="title3" weight="semibold">
          플러그인 생성
        </Heading>
        <Icon
          className='create-delete'
          control
          icon="delete"
          textBlind = {true}
          onClick={()=>{
            handleDelete();
          }}

        >
          삭제
        </Icon>
        <div className="h-side">
          <Button
            variant="secondary"
            size="large"
            onClick={() => {
              handleSave('temp', true);
            }}
          >
            임시저장
          </Button>
          <Button
            variant="primary"
            size="large"
            onClick={() => {
              handleSave('save');
            }}
            disabled={!(formData.createdStep === 3)}
          >
            배포하기
          </Button>
        </div>
      </div>

      <div className="create-container">
        <div className="create-form">
          <PluginCreateForm
            formData={formData}
            setFormData={setFormData}
            onSubmit={handleSubmit}
            handleSave={handleSave}
          />
        </div>
        <div className="create-test">
          <PluginCreatePreview formData={formData} handleSave={handleSave} />
        </div>
      </div>
    </div>
  );
};

export default PluginCreate;
