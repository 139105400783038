import React, {
  forwardRef,
  useState,
  useEffect,
  useRef,
  useCallback,
  ReactNode,
  CSSProperties,
  useMemo,
  ForwardedRef,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { Button } from './Button';
import AnimationState from './AnimationState';

type Position = 'top' | 'bottom' | 'start' | 'end' | Array<'top' | 'bottom' | 'start' | 'end'>;

// Props 타입 정의
interface PopoverProps {
  className?: string;
  trigger?: React.ReactNode;
  position?: Position;
  children: ReactNode;
  isOpen?: boolean;
  popoverState?: (isOpen: boolean) => void;
  inactiveEvent?: boolean;
  gap?: number;
  style?: CSSProperties;
}

function isRefObject<T>(ref: ForwardedRef<T>): ref is React.RefObject<T> {
  return ref !== null && typeof ref !== 'function';
}

// Popover 컴포넌트 정의
export const Popover = forwardRef<HTMLButtonElement | HTMLDivElement, PopoverProps>(
  (
    {
      className,
      trigger = 'trigger click',
      position = 'bottom',
      children,
      isOpen = false,
      popoverState,
      inactiveEvent = false,
      gap = 0,
      style,
      ...props
    },
    ref,
  ) => {
    const positions = useMemo(() => (Array.isArray(position) ? position : [position]), [position]);
    const [isPopoverOpen, setIsPopoverOpen] = useState(isOpen);
    const [shouldFocus, setShouldFocus] = useState(false);
    const popoverRef = useRef<HTMLDivElement>(null);
    // 9.23 lint 떄문에 임시 triggerRef 생성
    const localTriggerRef = useRef<HTMLButtonElement | HTMLDivElement>(null);
    const triggerRef = isRefObject(ref) ? ref : localTriggerRef;

    const startTrapRef = useRef<HTMLDivElement>(null);
    const endTrapRef = useRef<HTMLDivElement>(null);

    const closeDropdown = useCallback(() => {
      setIsPopoverOpen(false);
      if (popoverState) popoverState(false);

      let focusTarget = triggerRef.current;
      if (focusTarget && !(focusTarget instanceof HTMLButtonElement || focusTarget instanceof HTMLAnchorElement)) {
        focusTarget = focusTarget.querySelector('button, a');
      }
      if (focusTarget) {
        focusTarget.focus();
      }
    }, [popoverState]);

    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        if (
          popoverRef.current &&
          !popoverRef.current.contains(event.target as Node) &&
          !triggerRef.current?.contains(event.target as Node)
        ) {
          setIsPopoverOpen(false);
          if (popoverState) popoverState(false);
        }
      },
      [closeDropdown],
    );

    const handleDropdownToggle = () => {
      setIsPopoverOpen(!isPopoverOpen);
      if (popoverState) popoverState(!isPopoverOpen);
      setShouldFocus(true);
    };

    const setPopoverPosition = useCallback(() => {
      const target = triggerRef.current;
      const popover = popoverRef.current;

      if (!target || !popover) return;

      const { top, left, width, height } = target.getBoundingClientRect();
      const popoverWidth = popover.offsetWidth;
      const popoverHeight = popover.offsetHeight;
      const scrollX = window.scrollX || window.pageXOffset;
      const scrollY = window.scrollY || window.pageYOffset;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;

      let posX = left + scrollX;
      let posY = top + scrollY;
      const adjustedPos = positions;

      if (positions[0] === 'bottom' && viewportHeight - (top + height) < popoverHeight && top > popoverHeight) {
        adjustedPos[0] = 'top';
      } else if (positions[0] === 'top' && top < popoverHeight && viewportHeight - (top + height) > popoverHeight) {
        adjustedPos[0] = 'bottom';
      }

      switch (adjustedPos[0]) {
        case 'top':
          posY -= popoverHeight + gap;
          posX += width / 2 - popoverWidth / 2;
          break;
        case 'bottom':
          posY += height + gap;
          posX += width / 2 - popoverWidth / 2;
          break;
        default:
          break;
      }

      if (adjustedPos.length > 1) {
        switch (adjustedPos[1]) {
          case 'start':
            posX -= width / 2 - popoverWidth / 2;
            break;
          case 'end':
            posX += width / 2 - popoverWidth / 2;
            break;
          default:
            break;
        }
      }

      if (posX > 0 && posX + popoverWidth > viewportWidth) posX = viewportWidth - popoverWidth;
      posX = Math.max(posX, 0);

      popover.style.top = `${posY}px`;
      popover.style.left = `${posX}px`;
    }, [positions, triggerRef, popoverRef, gap]);

    useEffect(() => {
      setIsPopoverOpen(isOpen);
    }, [isOpen]);

    useEffect(() => {
      window.addEventListener('click', handleClickOutside);
      return () => {
        window.removeEventListener('click', handleClickOutside);
      };
    }, [handleClickOutside]);

    useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          closeDropdown();
        }
        // inactiveEvent일 때 popover영역으로 포커스 이동
        else if (
          event.key === 'Tab' &&
          inactiveEvent &&
          (document.activeElement === triggerRef.current ||
            (triggerRef.current && triggerRef.current.contains(document.activeElement)))
        ) {
          event.preventDefault();
          if (popoverRef.current) popoverRef.current.focus();
        }
      };

      if (isPopoverOpen) {
        window.addEventListener('keydown', handleKeyPress);
      } else {
        window.removeEventListener('keydown', handleKeyPress);
      }

      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [isPopoverOpen, closeDropdown]);

    const handleAnimationStart = () => {
      if (isPopoverOpen) {
        setPopoverPosition();
        if (popoverRef.current && !inactiveEvent && shouldFocus) {
          popoverRef.current.focus();
          setShouldFocus(false);
        }
      }
    };

    const handleTrapFocus = (event: React.FocusEvent<HTMLDivElement>) => {
      if (event.target === startTrapRef.current || event.target === endTrapRef.current) {
        closeDropdown();
      }
    };

    const handleScroll = useCallback((event: Event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        closeDropdown();
      }
    }, []);

    useEffect(() => {
      const debounce = (func: (...args: unknown[]) => void, wait: number) => {
        let timeout: number;
        return (...args: unknown[]): void => {
          clearTimeout(timeout);
          timeout = window.setTimeout(() => {
            func(...args);
          }, wait);
        };
      };

      const handleResize = debounce(() => {
        setPopoverPosition();
      }, 100);

      const target = triggerRef.current;

      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);

      // 컨테이너 내 스크롤 대응
      let parent = target?.parentElement;
      while (parent) {
        parent.addEventListener('scroll', handleScroll);
        parent = parent.parentElement;
      }
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('scroll', handleScroll);

        let parent = target?.parentElement;
        while (parent) {
          parent.removeEventListener('scroll', handleScroll);
          parent = parent.parentElement;
        }
      };
    }, [setPopoverPosition]);

    return (
      <>
        {React.isValidElement(trigger) ? (
          React.cloneElement(trigger as React.ReactElement, {
            ref: triggerRef,
            onClick: inactiveEvent ? null : handleDropdownToggle,
            role: 'combobox',
            'aria-expanded': isPopoverOpen,
            'aria-haspopup': 'true',
          })
        ) : (
          <Button
            ref={triggerRef as React.RefObject<HTMLButtonElement>}
            onClick={inactiveEvent ? undefined : handleDropdownToggle}
            role="combobox"
            aria-expanded={isPopoverOpen}
            aria-haspopup="true"
            disabled={inactiveEvent}
          >
            {trigger}
          </Button>
        )}
        {isPopoverOpen &&
          ReactDOM.createPortal(
            <AnimationState isActive={isPopoverOpen} nodeRef={popoverRef} onStart={handleAnimationStart}>
              <>
                <div
                  tabIndex={0}
                  ref={startTrapRef}
                  className="dsx-PseudoElement"
                  onFocus={handleTrapFocus}
                  aria-hidden="true"
                />
                <div
                  ref={popoverRef}
                  className={classNames('dsx-Popover', className)}
                  /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
                  data-state={isPopoverOpen ? 'show' : 'hide'}
                  onClick={(e) => {
                    e.stopPropagation();
                  }} // 이벤트 전파 방지
                  tabIndex={-1}
                  {...props}
                  style={style}
                >
                  {children}
                </div>
                <div
                  tabIndex={0}
                  ref={endTrapRef}
                  className="dsx-PseudoElement"
                  onFocus={handleTrapFocus}
                  data-focus="end"
                  aria-hidden="true"
                />
              </>
            </AnimationState>,
            document.body,
          )}
      </>
    );
  },
);
Popover.displayName = 'Popover';
