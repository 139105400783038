import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  CSSProperties,
} from 'react';
import classNames from 'classnames';
import { IconButton } from './IconButton';
import Text from './Text';


interface InputProps {
  type?: 'text' | 'password' | 'tel' | 'url' | 'search' | 'email';
  size?: 'small' | 'large' | 'xlarge';
  variant?: 'outlined' | 'filled';
  full?: boolean;
  invalid?: boolean;
  placeholder?: string;
  value?: string;
  slotBefore?: React.ReactNode;
  slotAfter?: React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  clearable?: boolean;
  reveal?: boolean;
  maxLength?: number;
  showCount?: boolean;
  className?: string;
  style?: CSSProperties;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onKeydown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  name?: string;
}

export const Input = forwardRef<HTMLDivElement, InputProps>(function Input(
  {
    type = 'text',
    size,
    variant = 'outlined',
    placeholder,
    value = '',
    slotBefore = null,
    slotAfter = null,
    className,
    style,
    full = false,
    invalid = false,
    clearable = false,
    reveal = false,
    maxLength = null,
    showCount = false,
    onChange,
    onFocus,
    onBlur,
    onClick,
    onKeydown,
    name,
    ...props
  },
  ref,
) {
  const [inputValue, setInputValue] = useState<string>(value);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputValue(value || '');
  }, [value]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    if (onChange) {
      onChange(event);
    }
  };

  const clearInput = () => {
    setInputValue('');
    inputRef.current?.focus();
    if (onChange) {
      onChange({ target: { value: "" } } as ChangeEvent<HTMLInputElement>);
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const focusInput = (event: MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).tagName.toLowerCase() !== 'button') {
      inputRef.current?.focus();
    }
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <>
      <div
        className={classNames(
          'dsx-Input',
          size && `dsx-Input--${size}`,
          `dsx-Input--${variant}`,
          full && 'dsx-w-full',
          invalid && 'is-invalid',
          className,
        )}
        style={style}
        onClick={focusInput}
        ref={ref}
      >
        {slotBefore}
        <input
          type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
          className="dsx-Input-field"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeydown}
          maxLength={maxLength ?? undefined}
          ref={inputRef}
          name={name}
          {...props}
        />
        {inputValue && clearable && (
          <IconButton name="closeFill" className="dsx-Input-option" onClick={clearInput}>
            지우기
          </IconButton>
        )}
        {inputValue && reveal && type === 'password' && (
          <IconButton
            name={showPassword ? 'eyeSlashFill' : 'eyeFill'}
            className="dsx-Input-option"
            onClick={toggleShowPassword}
          >
            {showPassword ? '숨기기' : '보이기'}
          </IconButton>
        )}
        {slotAfter}
      </div>
      {showCount && maxLength && (
        <Text variant="length">
          {inputValue.length}/{maxLength}
        </Text>
      )}
    </>
  );
});
