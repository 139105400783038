import { IPluginPromptCategoryList } from '../models/studio/Category';
import {
  IPluginPromptData,
  IPluginPromptList,
  IPluginPromptParam,
  IPluginPromptSave,
  IProfileImg,
} from '../models/studio/PluginPromptList';
import { BaseService } from './BaseService';

export class PromptService extends BaseService {
  public getAllPromptAsync = async (): Promise<IPluginPromptData[]> => {
    const result = await this.getResponseAsync<IPluginPromptList>({
      commandPath: `api/user/prompt`,
    });

    return result.data;
  };

  /**
   * [프롬프트] - 카테고리 목록 조회
   * @returns
   */
  public getPromptCategoryAsync = async (): Promise<IPluginPromptCategoryList> => {
    const result = await this.getResponseAsync<IPluginPromptCategoryList>({
      commandPath: `api/studio/category/prompt`,
      method: 'GET',
    });

    return result;
  };

  /**
   * [API] - 프롬프트 리스트 조회
   * @param obj
   * @returns
   */
  public getPromptListAsync = async (obj: IPluginPromptParam): Promise<IPluginPromptList> => {
    const query = new URLSearchParams();
    for (const key in obj) {
      const value = obj[key];
      if (value != null) {
        query.append(key, value.toString());
      }
    }

    const result = await this.getResponseAsync<IPluginPromptList>({
      commandPath: `api/studio/prompts`,
      query: query,
      method: 'GET',
    });

    return result;
  };

  /**
   * [API] - 프롬프트 상세 조회
   * @param companyCode
   * @param promptId
   * @returns
   */
  public getPromptDetailAsync = async (companyCode: string, promptId: string): Promise<IPluginPromptList> => {
    const result = await this.getResponseAsync<IPluginPromptList>({
      commandPath: `api/studio/prompt?companyCode=${companyCode}&promptId=${promptId}`,
      method: 'GET',
    });

    return result;
  };

  /**
   * [API] - 프롬프트 북마크(즐겨찾기)
   * @param companyCode
   * @returns
   */
  public getPromptBookmarkAsync = async (obj: IPluginPromptParam): Promise<IPluginPromptList> => {
    const query = new URLSearchParams();
    for (const key in obj) {
      const value = obj[key];
      if (value != null) {
        query.append(key, value.toString());
      }
    }

    const result = await this.getResponseAsync<IPluginPromptList>({
      commandPath: `/api/studio/prompts/bookmark`,
      query: query,
      method: 'GET',
    });

    return result;
  };

  /**
   * [API] - 프롬프트 검색
   * @param obj
   * @returns
   */
  public getPromptSearchAsync = async (obj: IPluginPromptParam): Promise<IPluginPromptList> => {
    const query = new URLSearchParams();
    for (const key in obj) {
      const value = obj[key];
      if (value != null) {
        query.append(key, value.toString());
      }
    }
    const result = await this.getResponseAsync<IPluginPromptList>({
      commandPath: `/api/studio/prompts/search`,
      query: query,
      method: 'GET',
    });

    return result;
  };

  /**
   * [API] - 내 프롬프트 조회
   * @param obj
   * @returns
   */
  public getMyPromptListAsync = async (obj: IPluginPromptParam): Promise<IPluginPromptList> => {
    const query = new URLSearchParams();
    for (const key in obj) {
      const value = obj[key];
      if (value != null) {
        query.append(key, value.toString());
      }
    }

    const result = await this.getResponseAsync<IPluginPromptList>({
      commandPath: `api/studio/prompts/my`,
      query: query,
      method: 'GET',
    });

    return result;
  };

  /**
   * [API] - 프롬프트 생성
   * @param obj
   * @returns
   */
  public postPromptAsync = async (obj: IPluginPromptData): Promise<IPluginPromptSave> => {
    const result = await this.getResponseAsync<IPluginPromptSave>({
      commandPath: `/api/studio/prompt`,
      method: 'POST',
      body: obj,
    });

    return result;
  };

  /**
   * [API] - 프롬프트 삭제
   * @param obj
   * @returns
   */
  public deletePromptAsync = async (obj: IPluginPromptData): Promise<IPluginPromptData> => {
    const result = await this.getResponseAsync<IPluginPromptData>({
      commandPath: `/api/studio/prompt`,
      method: 'DELETE',
      body: { id: obj.id },
    });

    return result;
  };

  /**
   * [API] - 프롬프트 상세 조회
   * @param obj
   * @returns
   */
  public getPromptAsync = async (promptId: string): Promise<IPluginPromptData> => {
    const result = await this.getResponseAsync<IPluginPromptData>({
      commandPath: `/api/studio/prompt?promptId=${promptId}`,
      method: 'GET',
    });

    return result;
  };

  /**
   * [API] - 프롬프트 수정
   * @param obj
   * @returns
   */
  public putPromptAsync = async (obj: IPluginPromptData): Promise<IPluginPromptSave> => {
    const result = await this.getResponseAsync<IPluginPromptSave>({
      commandPath: `/api/studio/prompt`,
      method: 'POST',
      body: obj,
    });

    return result;
  };

  /**
   * [API] - 프롬프트 북마크(즐겨찾기) 수정
   * @param obj
   * @returns
   */
  public postPromptBookmarkAsync = async (promptId: string): Promise<IPluginPromptData> => {
    const result = await this.getResponseAsync<IPluginPromptData>({
      commandPath: `/api/studio/bookmark/prompt`,
      method: 'POST',
      body: { id: promptId },
    });

    return result;
  };

  /**
   * [API] - 프롬프트 북마크(즐겨찾기) 삭제
   * @param obj
   * @returns
   */
  public deletePromptBookmarkAsync = async (promptId: string): Promise<IPluginPromptData> => {
    const result = await this.getResponseAsync<IPluginPromptData>({
      commandPath: `/api/studio/bookmark/prompt`,
      method: 'DELETE',
      body: { id: promptId },
    });

    return result;
  };

  /**
   * [API] - 오늘 등록 갯수
   * @param obj
   * @returns
   */
  public getPromptTodayCountAsync = async (createdStatus: number) => {
    const result = await this.getResponseAsync<IPluginPromptList>({
      commandPath: `/api/studio/prompt/today?createdStatus=${createdStatus}`,
      method: 'GET',
    });

    return result;
  };

  /**
   * [API] - DALLE 이미지 생성
   * @param obj
   * @returns
   */
  public getDalleImg = async (prompt: string): Promise<string> => {
    const result = await this.getResponseAsync<string>({
      commandPath: `/api/studio/profile/dalle`,
      method: 'POST',
      body: { prompt: prompt },
    });

    console.log('promptService getDalleImg resut : ', result);
    return result;
  };

  /**
   * [API] - 이미지 업로드
   * @param customPluginId
   * @param formData
   * @returns
   */
  public postProfileImg = async (customPluginId: string, formData: FormData) => {
    const params = new URLSearchParams();
    params.append('customPluginId', customPluginId);
    params.append('isDraft', 'true');

    const result = await this.getResponseAsync<IProfileImg>({
      commandPath: `/api/studio/profile`,
      method: 'POST',
      query: params,
      body: formData,
    });

    return result;
  };

  /**
   * [API] - 프롬프트 임시저장
   * @param obj
   * @returns
   */
  public postPromptDraftAsync = async (obj: IPluginPromptData): Promise<IPluginPromptSave> => {
    const result = await this.getResponseAsync<IPluginPromptSave>({
      commandPath: `/api/studio/prompt/draft`,
      method: 'POST',
      body: obj,
    });

    return result;
  };

  /**
   * [API] - 임시저장된 상태에서 아무것도 변경되지 않고 상태만 등록으로 변경
   * @param obj
   * @returns
   */
  public postPromptStatusAsync = async (obj: IPluginPromptData): Promise<IPluginPromptSave> => {
    const result = await this.getResponseAsync<IPluginPromptSave>({
      commandPath: `/api/studio/prompt/Status`,
      method: 'POST',
      body: obj,
    });

    return result;
  };
}
