import { useState, useEffect, forwardRef, ChangeEvent } from 'react';
import { Popover, Button, ButtonArea, Input, Checkbox, CheckboxGroup, Empty, IconButton } from './base';
//import { UserData } from '../libs/models/users/UsersState';
import { usePlugin } from '../libs/hooks/usePlugin';
import { UserData } from '../libs/models/users/UsersState';
import { showAlertToast } from '../redux/features/app/appSlice';
import { AlertType } from '../libs/models/AlertType';
import { useAppDispatch } from '../redux/app/hooks';
import { Text } from './base/Text';

interface Option {
  value: string,
  label: string,
  disabled?: boolean;
}

interface UserSelectorProps {
  selectedUsers: UserData[];
  disabledUsers: UserData[];
  onUserSelect?: (user: UserData[]) => void;
  onUserRemove?: (user: UserData[]) => void;
  buttonLabel?: string;
  disabled: boolean;
  maxUsers: number;
  showCount: boolean;
}

const LoadingSpinner = () => {
  return (
    <div className="dsx-ModalOverlay">
      <div className="works-loading">
        <span className="loading" aria-hidden="true"></span>
      </div>
    </div>
  );
};

const UserSelector = forwardRef<HTMLDivElement, UserSelectorProps>(({
  selectedUsers = [],
  disabledUsers = [],
  onUserSelect,
  onUserRemove,
  buttonLabel = '관리자 추가',
  disabled = false,
  maxUsers,
  showCount
}, ref) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const [members, setMembers] = useState<UserData[]>([]);
  const [options, setOptions] = useState<Option[]>([]);
  const [loading, setLoading] = useState(false);
  const [emptyDesc, setEmptyDesc] = useState('검색어를 입력해주세요.');

  const dispatch = useAppDispatch();
  const studio = usePlugin();

  useEffect(() => {
    if (open) {
      const newValues = selectedUsers.map(user => user.userId);
      setSelectedValues(newValues);
    } else {
      setMembers([]);
      setInputValue('');
    }
  }, [open, selectedUsers]);

  useEffect(() => {
    
    const values: Option[] = members.map(user => {
      console.log(disabledUsers.map(user=>user.userId).includes(user.userId))
      return {
        value: user.userId,
        label: `${user.name}` + (user.deptName ? ` (${user.deptName})` : ''),
        disabled: disabledUsers.map(user=>user.userId).includes(user.userId) ? true: false, 
      } as Option;
    })
    setOptions(values);
    setLoading(false);
  }, [members])

  const handleSelectChange = (values: string[]) => {
    setSelectedValues(values);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    if (event.target.value.length == 0) {
      setMembers([]);
      setEmptyDesc('검색어를 입력해주세요.');
    }
  };

  const changedPopoverState = (isOpen: boolean) => {
    setOpen(isOpen);
    if (isOpen) {
      setInputValue('');
    }
  };

  const handleConfirm = () => {
    // 현재 selectedValues에 없는 기존 선택된 사용자들을 찾아 제거
    const removedUsers = selectedUsers.filter(
      user => !selectedValues.includes(user.userId)
    );

    // 새로 선택된 사용자들 찾기
    const newSelectedUsers = members.filter(
      user => selectedValues.includes(user.userId)
    );

    if (removedUsers.length > 0) {
      // 제거된 사용자들을 제외한 최종 사용자 목록
      const finalUsers = selectedUsers.filter(
        user => !removedUsers.some(removedUser => removedUser.userId === user.userId)
      );
      // 새로 선택된 사용자들 추가
      const updatedUsers = Array.from(new Map(
        [...finalUsers, ...newSelectedUsers].map(user => [user.userId, user])
      ).values());
      if(updatedUsers.length > maxUsers) {
        dispatch(
          showAlertToast({
            altMessage: String(`기여자는 최대 ${maxUsers}명까지만 지정이 가능합니다.`),
            altType: AlertType.Info,
          }),
        );
        return;
      }
      if (onUserSelect) onUserSelect(updatedUsers);
    } else if (newSelectedUsers.length > 0) {
      // 새로 선택된 사용자만 있는 경우
      const updatedUsers = Array.from(new Map(
        [...selectedUsers, ...newSelectedUsers].map(user => [user.userId, user])
      ).values());
      if(updatedUsers.length > maxUsers) {
        dispatch(
          showAlertToast({
            altMessage: String(`기여자는 최대 ${maxUsers}명까지만 지정이 가능합니다.`),
            altType: AlertType.Info,
          }),
        );
        return;
      }
      if (onUserSelect) onUserSelect(updatedUsers);
    }
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
    setInputValue('');
    setSelectedValues(selectedUsers.map(user => user.userId));
  };

  const handleUserRemove = (userId: string) => {
    const updatedUsers = selectedUsers.filter(user => user.userId !== userId);
    if (onUserRemove) onUserRemove(updatedUsers);
    setSelectedValues(prev => prev.filter(value => value !== userId));
  };

  const filteredValues = options.map(user => user.value);
  const selectedFilteredCount = selectedValues.filter(value =>
    filteredValues.includes(value)
  ).length;

  const isAllSelected = options.length > 0 && selectedFilteredCount === options.length;
  const isIndeterminate = selectedFilteredCount > 0 && selectedFilteredCount < options.length;

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    if (checked) {
      const newValues = options.filter(user => !user.disabled).map(user => user.value);
      // 기존 값들과 병합
      setSelectedValues(prev => Array.from(new Set([...prev, ...newValues])));
    } else {
      // 현재 필터된 값들만 제거
      setSelectedValues(prev => prev.filter(value => !filteredValues.includes(value)));
    }
  };

  const handleKeydown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const newValue = inputValue;
    if (inputValue.trim().length < 2) return;
    if (event.key === 'Enter') {
      setLoading(true);
      event.preventDefault();
      studio.getMembers(newValue).then((r) => {
        setMembers(r);
        if(r.length == 0) setEmptyDesc('검색결과가 없습니다.');
      }).catch((e) => {
        console.error("사용자 조회 오류", e);
      })
    }
  }

  return (
    <>
      <Popover
        isOpen={open}
        popoverState={changedPopoverState}
        trigger={<Button variant={open ? "soft" : "secondary"} size="large" prefixIcon="plusFill" disabled={disabled}>{buttonLabel}</Button>}
        position={['bottom', 'start']}
        gap={8}
      >
        <div className="dsx-Popover-inner userselect-popover" onKeyDown={handleKeydown}>
          <Input
            type="text"
            variant="filled"
            size="large"
            placeholder="직원 / 팀명을 입력해 주세요"
            full
            clearable
            value={inputValue}
            onChange={handleSearchChange}
          />
          <div className="userselect-field">
            {loading && <LoadingSpinner />}
            {/* {inputValue === '' ? (
              <Empty
                image="images/ico_load.svg"
                desc={emptyDesc}
                className="field-empty"
              />
            ) : ( */}
              <>
                {options.length > 0 ? (
                  <>
                    <div className="field-head">
                      <Checkbox
                        size="large"
                        checked={isAllSelected}
                        indeterminate={isIndeterminate}
                        onChange={handleSelectAll}
                      >
                        전체 선택
                      </Checkbox>
                    </div>
                    <div className="field-body">
                      <CheckboxGroup
                        size="large"
                        name="userGroup"
                        options={options}
                        value={selectedValues}
                        onChange={handleSelectChange}
                        vertical
                      />
                    </div>
                  </>
                ) : (
                  <Empty
                    image="images/ico_load.svg"
                    desc={emptyDesc}
                    className="field-empty"
                  />
                )}
              </>
            {/* )} */}
          </div>
          <ButtonArea align="full">
            <Button
              variant="outline"
              size="large"
              onClick={handleCancel}
            >
              취소
            </Button>
            <Button
              variant="primary"
              size="large"
              onClick={handleConfirm}
            >
              추가
            </Button>
          </ButtonArea>
        </div>
      </Popover>
      {selectedUsers.length > 0 && (
        <div ref={ref} className="userselect-panel">
          {selectedUsers.map((user) => (
            <span key={user.userId} className="tag-user">
              {user.name} {user.deptName != null ?' (' + user.deptName + ')' : ''}
              <IconButton
                name="closeFill"
                size="medium"
                onClick={() => { handleUserRemove(user.userId) }}
                disabled={disabled}
              >
                삭제
              </IconButton>
            </span>
          ))}
        </div>
      )}
      {showCount && (
        <Text variant="length">
          {selectedUsers.length}/{maxUsers}
        </Text>
      )}
    </>
  );
});


UserSelector.displayName = 'UserSelector';

export default UserSelector;